import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';
import { AuthenticationService } from './../services/authentication.service';
import { ApiserviceService } from '../services/apiservice.service';
import { User } from './entities';

@Injectable({
    providedIn: 'root'
  })

  export class UserManagerProviderService {

    //userLangue = 'fr';
    currentUser: User;

    constructor(public apiService: ApiserviceService,
        public platform: Platform,
        public authentificationService: AuthenticationService)
        {
    }


    saveUser() {
      console.log('Save user');
      this.apiService.setLocalData(this.apiService.appName + '_currentUser', JSON.stringify(this.currentUser));
    //   Storage.set({ key: this.apiService.appName + '_currentUser', value: JSON.stringify(this.currentUser) });
      // sauve online full user
       //TODO
    }


    setUser(user) {
      let aUser = new User().initWithJSON(user);
      this.currentUser = aUser;
      console.log('User ID ' + aUser.id + ' email ' + aUser.email);
      console.log('=====cle ' + this.apiService.appName + ' on save user ');
      this.apiService.setLocalData(this.apiService.appName + '_currentUser', JSON.stringify(user));
    //   Storage.set({ key: this.apiService.appName + '_currentUser', value: JSON.stringify(user) });
    }


    getUser() {
      return new Promise(async resolve => {
        console.log('Get user ' + this.apiService.appName + '_currentUser');
        let result = await this.apiService.getLocalData(this.apiService.appName + '_currentUser');
        // let result = await Storage.get({ key: this.apiService.appName + '_currentUser' });

        if (result != null) {
            let aUser = new User().initWithJSON(JSON.parse(String(result)));
            this.currentUser = aUser;
            resolve(this.currentUser);
        }
        else {
          //Get user
          console.log('Looking for user');
          this.apiService.getUserMe().subscribe((res)=>{
              let status = res["status"];
              if (status=="OK") {
                let data = res["data"];
                this.setUser(data);
                resolve(this.currentUser);
              }
              else{
                resolve(null);
              }
          });
        }
      });
    }


    logoutUser() {
      return new Promise(async resolve => {
        // on doit le passe offline
        console.log('-------- LOGOUT USER --------');
        // logout API
        this.authentificationService.logout().then(async ()=>{
          this.currentUser = null;
          let result = this.apiService.removeLocalData(this.apiService.appName + '_currentUser');
        //   let result = await Storage.remove({ key: this.apiService.appName + '_currentUser' })
          resolve(true);
        });
      });
    }

  }
