/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable,BehaviorSubject } from 'rxjs';
import { from, of, forkJoin } from 'rxjs';
import { catchError, retry, map,tap } from 'rxjs/operators';
import { AlertController, LoadingController } from '@ionic/angular';
import * as Constant from '../config/constants';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';


export enum ConnectionStatus {
    Online,
    Offline
  }


@Injectable({
    providedIn: 'root'
})

export class ApiserviceService {

    private _storage: Storage | null = null;

    public status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.Offline);
    public tokenSet: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);

    public tokenSSO: String = '';
    networkConnected: boolean = true;
    virtualHostName: string = ''
    appName: string = '';
    apiPrefix = '/api';
    loader: any;
    expireDate: any;
    isOnline = false;
    urlPwdOublie: string = '';
    checkUrl: string = '';
    cordovaiOS = false;
    isShowingLoader = false;
    getCustomUserUrl : string ='';
    auth_token = '';
    userId = null;

    // ================ AUTHENTIFICATION METHODS ====================

    getLoginUrl: string="";

    getResetPwdUrl: string="";
    getRefreshTokenUrl: string =""
    getMeUrl: string='';

    getUserUrl: string='';

    getAppProfileUrl: string='';

    constructor(public http: HttpClient,
      private storage: Storage,
      public loadingController: LoadingController,
      public alertCtrl: AlertController,
      public platform: Platform){
          this.init();
          this.initProvider(Constant.domainConfig.virtual_host, Constant.domainConfig.client, "api");
          this.http = http;
    }

    async init() {
      // If using, define drivers here: await this.storage.defineDriver(/*...*/);
      const storage = await this.storage.create();
      this._storage = storage;
    }

    initProvider(url, app_name, apiPrefix) {
        this.virtualHostName = url;
        this.appName = app_name;
        this.apiPrefix = apiPrefix;
        console.log('init provider appName ' + this.appName);
        this.initUrls();
    }

    private initUrls() {
      //Default urls
      this.checkUrl = this.virtualHostName + this.apiPrefix + '/checkAPI/';
      // ================ AUTHENTICATION METHODS ====================
      this.getLoginUrl =  this.virtualHostName + 'auth/jwt/create/';
      this.getUserUrl  =  this.virtualHostName + 'auth/users/';
      this.getResetPwdUrl = this.virtualHostName + 'auth/users/reset_password/';
      this.getRefreshTokenUrl = this.virtualHostName + 'auth/jwt/refresh/';
      this.getMeUrl =  this.virtualHostName + 'auth/users/me/';
      // =================================================================
      this.getUserUrl = this.virtualHostName + this.apiPrefix + '/user/';
      this.getAppProfileUrl = this.virtualHostName + this.apiPrefix + '/appprofile/';
      }


    private async updateNetworkStatus(status: ConnectionStatus) {
        this.status.next(status);
        this.networkConnected = status === ConnectionStatus.Offline ? false : true;
        console.log('networkConnected '+this.networkConnected);
    }

    public onNetworkChange(): Observable<ConnectionStatus> {
        return this.status.asObservable();
    }

    public getCurrentNetworkStatus(): ConnectionStatus {
        return this.status.getValue();
    }

    // Local Data
    public setLocalData(key, jsonData) {
        return new Promise(async resolve => {
            //   console.log("=ON enregistre cle "+Constant.domainConfig.client+"-"+key+" valeur "+JSON.stringify(jsonData))
            const value = JSON.stringify(jsonData);
            await this._storage?.set(key, value);
            resolve(true);
        });
    }

    public async removeLocalData(key){
        await this._storage?.remove(key);
    }

    // Get cached API result
    public getLocalData(key) {
        return new Promise(async resolve => {
          const ret = await this._storage?.get(key);

            if (ret){
                resolve( JSON.parse(ret.value));
            }
            else{
                resolve(null);
            }
        });
    }

    saveAccessToken(token){
      this.auth_token = token;
    }

    getAccessToken(){
      return this.auth_token;
    }

    // ================ AUTHENTIFICATION METHODS ====================
    refreshToken(token){
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        let params = {
            "refresh": token
        };
        console.log("=== On demande refresh token avec ",params);
        return this.http.post(this.getRefreshTokenUrl, params, options).pipe(
            tap(response => {
                console.log("=== REFRESH reponse",response);
                this.setLocalData("access",{"access":response["access"]});
                this.setLocalData("refresh",{"refresh":response["refresh"]});

            })
        );
    }


    login(params){
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return new Observable(observer=>{
            this.http.post(this.getLoginUrl, params, options).subscribe(
                (val) => {
                    observer.next(val);
                    observer.complete();
                 },
                 response => {
                     console.log("POST call in error", response);
                     observer.next();
                     observer.complete();
                 },
                () => {

                });
        });
    }

    registerUser(params){
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        console.log("URL "+this.getUserUrl);

        return new Observable(observer=>{
            this.http.post(this.getUserUrl, params, options).subscribe(
                (val) => {
                    observer.next({"status":"OK","data":val})
                    observer.complete();
                 },
                 response => {
                     console.log("POST call in error", response);
                     observer.next({"status":"KO","error":response})
                     observer.complete();
                 },

                () => {

                });
        });
    }

    getUserMe(){
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `JWT ${this.auth_token}`
            })
        };

        console.log(this.auth_token);

        return new Observable(observer=>{
            this.http.get(this.getMeUrl, options).subscribe(
                (val) => {
                    observer.next({"status":"OK","data":val})
                    observer.complete();
                    this.userId = val["id"];
                 },
                 response => {
                     console.log("POST call in error", response);
                     observer.next({"status":"KO","error":response})
                     observer.complete();
                 },

                () => {

                });
        });
    }


    getUserId(){
      return this.userId;
    }


    createUser(modelToCreate) {
        // model JSON
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        const params = JSON.stringify(modelToCreate)
        console.log("URL "+this.getUserUrl)
        return this.http.post(this.getUserUrl, modelToCreate, options).pipe(retry(1))
    }

    getAllUser() {
      const url = this.getUserUrl;
        return this.findUser(url);
    }

    findUserWithQuery(query) {
        const url = this.getUserUrl + query;
        return this.findUser(url);
    }


    private findUser(url) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return Observable.create(observer => {
            // At this point make a request to your backend to make a real check!
            console.log('call url ' + url);
            this.http.get(url, options)
                .pipe(retry(1))
                .subscribe(res => {
                    observer.next(res);
                    observer.complete();
                }, error => {
                    observer.next();
                    observer.complete();
                    console.log(error);// Error getting the data
                });
        });
        }


    getUserDetails(id){
        const options = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.tokenSSO,
                'Content-Type': 'application/json'
            })
        };
        return Observable.create(observer => {
            // At this point make a request to your backend to make a real check!
            this.http.get(this.getUserUrl + id + "/", options)
                .pipe(retry(1))
                .subscribe(res => {
                    this.networkConnected = true;
                    observer.next(res);
                    observer.complete();
                }, error => {
                    observer.next(false);
                    observer.complete();
                    console.log(error);// Error getting the data
                });
        });
    }


    updateUser(id, patchParams) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return Observable.create(observer => {
            // At this point make a request to your backend to make a real check!
            this.http.patch(this.getUserUrl + id + "/", patchParams, options)
                .pipe(retry(1))
                .subscribe(res => {
                    this.networkConnected = true;
                    observer.next(true);
                    observer.complete();
                }, error => {
                    observer.next(false);
                    observer.complete();
                    console.log(error);// Error getting the data
                });
        });
    }


    createAppProfile(modelToCreate) {
        // model JSON
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };


        console.log("URL "+this.getAppProfileUrl);
        return this.http.post(this.getAppProfileUrl, modelToCreate, options).pipe(retry(1))
    }


    //Override
    async showNoNetwork() {
        const alert = await this.alertCtrl.create({
            header: 'Désolé',
            message: 'Pas de réseau détecté. Merci de vérifier votre connexion 3G/4G ou Wifi',
            buttons: ['OK']
        });
        return await alert.present();
    }

    async showLoading() {
      console.log('SHOW LOADING');
      if (!this.isShowingLoader){
        this.isShowingLoader=true;
          this.loader = await this.loadingController.create({
            message:  'Moment geduld..',
            duration: 4000
          });
          return await this.loader.present();
      }
    }

    async stopLoading() {
      console.log('STOP LOADING?');
      if (this.loader){
        this.loader.dismiss();
        this.loader =  null;
        this.isShowingLoader=false;
      }
    }

    public async showLoadingMessage(message) {
      this.loader = await this.loadingController.create({
        message: message,
      });
      this.loader.present();
    }


    /**
    * Show error message
    *
    * @param text - The message to show
    *
    */
    async showError(text) {
        const alert = await this.alertCtrl.create({
            header: 'Fout',
            message: text,
            buttons: ['OK']
        });
        return await alert.present();
    }

    /**
    * Show a message
    *
    * @param title - The title of the message to show
    * @param message - The text of the message to show
    *
    */
    async showMessage(title, message) {
        const alert = await this.alertCtrl.create({
            header: title,
            message: message,
            buttons: ['OK']
        });
        return await alert.present();
    }


    checkAPI() {
        return Observable.create(observer => {
            // At this point make a request to your backend to make a real check!
            this.http.get(this.checkUrl)
                .pipe(
                    retry(1)
                )
                .subscribe(res => {
                    this.networkConnected = true;
                    observer.next(true);
                    observer.complete();
                }, error => {
                    observer.next(false);
                    observer.complete();
                });
        });
    }

}
