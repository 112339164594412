import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'add-count',
    loadChildren: () => import('./add-count/add-count.module').then( m => m.AddCountPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'add-broken',
    loadChildren: () => import('./add-broken/add-broken.module').then( m => m.AddBrokenPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'change-location',
    loadChildren: () => import('./change-location/change-location.module').then( m => m.ChangeLocationPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'add-stock',
    loadChildren: () => import('./add-stock/add-stock.module').then( m => m.AddStockPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'search-results',
    loadChildren: () => import('./search-results/search-results.module').then( m => m.SearchResultsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'history',
    loadChildren: () => import('./history/history.module').then( m => m.HistoryPageModule),
    canLoad: [AuthGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
